/* Account signin page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    .field-container--grid .form-item {
      float: none;
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
      clear: both;
    }
    .divider {
      .social-login__divider {
        .inner {
          padding: 4px 9px;
          #{$ldirection}: 52vw;
          top: -96px;
          @include breakpoint($bp--xlarge-up) {
            top: -89px;
            #{$ldirection}: 575px;
          }
        }
      }
    }
    .social-login-section {
      margin-top: 127px;
    }
  }
  .social-login {
    &__email_opt_in {
      margin-top: 0;
      margin-#{$ldirection}: 15px;
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 65px;
      }
    }
    &__divider {
      @include swap_direction(margin, 11px auto 8px);
    }
    &__terms {
      text-align: $ldirection;
      margin-bottom: 0;
      @include breakpoint($bp--medium-up) {
        margin-bottom: 10px;
      }
    }
    .social-login__divider .inner {
      padding: 4px 9px;
    }
  }
}
/* GNAV styling */
.site-header {
  &__tools {
    .site-my-mac__contents {
      height: 500px;
      overflow-y: auto;
      @include breakpoint($bp--xxlarger-up) {
        height: auto;
      }
    }
  }
}

.social-login.gnav,
.confirm-container {
  .social-login__divider {
    .inner {
      padding: 4px 9px;
    }
  }
}
/* Checkout page Styling */
.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-clear {
        clear: both;
      }
      .social-login {
        &__terms {
          text-align: $ldirection;
          padding-#{$ldirection}: 0;
          margin-top: 15px;
        }
        &__divider .inner {
          padding: 4px 9px;
        }
        &__container {
          padding-bottom: 4px;
          @include breakpoint($bp--medium-up) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  &__return-user {
    margin-bottom: 13px;
    @include breakpoint($bp--medium-up) {
      margin-bottom: 0;
    }
  }
}

.social-login {
  &__email-opt-in {
    margin-top: 10px;
  }
  .picker-label {
    float: none;
    text-align: $ldirection;
    margin-#{$ldirection}: 39px;
  }
}
