.site-container {
  #hppopup {
    p {
      padding: 6px;
    }
    h2 {
      font-size: 2rem;
      line-height: 1.075;
    }
    a {
      color: $color--white;
      text-decoration: none;
      border-bottom: 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  #email_sms_signup {
    .form--newsletter__subtitle {
      text-align: center;
    }
  }
  .hero-block__wrapper {
    .max-width-100 {
      width: 100%;
    }
  }
}
