@mixin textboxshadow {
  -webkit-appearance: caret;
  border: 0 !important;
  outline: 1px solid $color-cta-grey;
}

.device-mobile {
  .cart-dialog.cart-popup {
    overflow: hidden;
    height: auto;
    .end-chat {
      width: 115px;
      a#end_chat {
        border: none;
      }
    }
  }
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      .temp-out-of-stock {
        display: block;
      }
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 250px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

.field {
  .email_input {
    float: left;
    width: 70%;
  }
  .align-r {
    float: left;
    margin-left: 10px;
    .input-btn {
      height: 58px;
    }
  }
  .terms_condition_cont {
    float: right;
    margin: 10px 5px 0 0;
  }
}

.site-container {
  .product__footer {
    a.notify_me {
      padding: 20px 0;
      width: 100%;
      margin: 0 !important;
      position: static;
    }
  }
  .favorites-page {
    .favorites-board__list {
      .pyramid-grid {
        .favorites-board__item {
          .product__footer {
            a.notify_me {
              display: none;
            }
            .product-item__out-of-stock {
              text-align: right;
            }
            .product-item__unavailable-message {
              float: none;
              line-height: 1.5;
              padding-left: 0;
              text-align: right;
            }
          }
        }
      }
      .grid--mpp__item {
        .product__detail {
          .product__footer {
            .product-item__out-of-stock {
              text-align: right;
            }
            a.notify_me {
              display: none;
            }
          }
        }
      }
    }
    .favorites-page__content {
      .product__footer {
        .product-item__unavailable-message {
          float: none;
          text-align: right;
        }
      }
      .favorites-page__header {
        &-description {
          padding: 0 1em;
        }
      }
      .favorites-none {
        &__text {
          padding: 0 1em;
        }
      }
    }
  }
  .account-page__content,
  .past-purchases-page {
    .grid--mpp {
      .product--teaser {
        .product-item__details {
          .product__footer {
            .product-item__add-to-cart {
              .product-item__unavailable-message {
                float: right;
                margin-right: 40px;
              }
              a.notify-me {
                display: none;
              }
            }
          }
          .product__faves--teaser {
            display: block;
          }
        }
      }
    }
  }
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          > li {
            text-align: right;
            float: none;
            &.coming-soon-text {
              text-align: center;
            }
          }
        }
      }
      &.product--orderable {
        &.product--shoppable {
          &.prod_inv_status-2 {
            .product__footer {
              .hide-non-shoppable-product {
                display: none;
              }
              .product__inventory-status {
                li.temp-out-of-stock {
                  display: block;
                }
              }
            }
            &.product--shaded {
              .product__footer {
                .product__inventory-status {
                  li.temp-out-of-stock {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .store-locator {
    .form--search--advanced {
      .form--search {
        padding: 0 0 74px;
      }
    }
  }
  .artistry-artists-page__content {
    .artistry-artists__pager {
      padding: 35px 0 35px;
    }
  }
  .contact-us-page {
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 0 5%;
    .contact-nav li {
      border: 0;
      min-height: 0;
    }
  }
}

.page--spp__product {
  .product__detail {
    .product__header {
      .product__name {
        word-wrap: break-word;
        width: 75%;
        white-space: normal;
      }
    }
    .product__footer {
      .product__inventory-status {
        .notify_me {
          display: none;
        }
      }
      .product__inventory-status {
        > li {
          text-align: center;
        }
      }
      .product_content_fav_mobile {
        .product__add-to-faves {
          top: 0;
          border: 0;
          margin-top: 0px;
          background: none;
          .icon--heart-o {
            top: 0;
          }
        }
      }
    }
  }
}

.section-stores {
  .site-container {
    min-height: 320px;
  }
}

.product--not-shoppable {
  .product--full {
    .product__add-to-faves {
      margin-top: 0;
    }
  }
}

.section-esearch {
  .search-results {
    .search-results__summary {
      .search-results__no-results--header {
        padding: 2em 15px;
      }
    }
  }
}

.panel {
  [type='text'],
  [type='password'],
  input:required.error,
  input:required:invalid {
    @include textboxshadow;
  }
}

[type='text'],
[type='password'] {
  @include textboxshadow;
}

[type='text']:focus,
[type='password']:focus {
  outline: 1px solid #000;
}

input.error {
  outline: 1px solid red;
}

.select2-drop {
  .select2-search {
    display: none;
  }
}

.site-header {
  .site-header__prefix {
    .site-logo a {
      background: no-repeat 50%;
    }
  }
}

#perlgem-search-form {
  .header-search__form--content {
    input:focus {
      outline: none !important;
    }
  }
}

.account-nav {
  .sidebar-profile-pic {
    vertical-align: top;
    width: 100%;
    float: none;
  }
}

.device-mobile {
  .show-xlarge-up--block {
    display: none;
  }
  .artistry-videos {
    &__formatter {
      margin-bottom: 0;
    }
  }
  .product__reviews-spp {
    .BVRRQuickTakeSummaryBasedOnCurrentFilters {
      top: -10px;
      width: 250px;
    }
    .BVDI_QTDashboard.BVDI_QTDisplayStyle2 {
      height: auto;
    }
  }
}

.site-container {
  #BVRRContainer {
    .BVDI_QTSummaryBox {
      padding-left: 6px !important;
    }
  }
}

.checkout {
  #payment-panel {
    .cvv {
      input {
        width: 16em;
      }
    }
  }
}

.product__reviews-spp {
  .BVRRCustomBasicInformation,
  .BVSectionBody {
    .BVTextField,
    .BVTextField {
      padding: 1px;
    }
  }
}

.mac-tooltip {
  .tooltipster-content {
    padding: 10px 3px;
    font-size: 11px;
  }
}

.section-products {
  .site-banner__sticky-fix {
    .sec-nav__sections {
      display: none;
    }
  }
}

.section-terms-conditions,
.section-counterfeit-education,
.section-privacy-policy {
  .site-container {
    padding: 0 20px;
  }
}

.shades--12 .shade-box {
  cursor: pointer;
}

#confirmation-page {
  #registration-panel {
    padding: 2em;
    margin-left: 0px;
    margin-bottom: 120px;
  }
}

.section-esearch {
  .search-results__filters {
    .results-header {
      margin: 0;
      border-top: 0;
    }
  }
}

.site-container {
  .artistry--artist {
    .artist--products__carousel-slide {
      .prod_inv_status-2 {
        .product__footer {
          .temp-out-of-stock__msg {
            display: block;
            float: #{$rdirection};
            @include swap_direction(padding, 6px 20px);
            height: 30px;
            background-color: $color--gray--light;
            color: $color--white;
            font-family: $ano-bold_regular-font;
            font-size: 15px;
          }
        }
      }
      .prod_inv_status-3 {
        .product__footer {
          .coming-soon-text {
            display: block;
          }
        }
      }
    }
  }
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $color--border;
    clear: both;
    padding: 10px;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 15px;
    li {
      padding-bottom: 1em;
    }
  }
}

.product-foundation-vto {
  &__btn {
    width: 300px;
  }
  &__overlay-button {
    width: 250px;
  }
}

.mac-select-loyalty-footer-formatter {
  .col-md-4 {
    padding: 0;
  }
}

.sign-in-page {
  [type='password'] {
    margin-bottom: 10px;
  }
}

.site-header-formatter {
  .site-header-formatter__sections {
    .site-header-formatter-search-input-field {
      outline: none;
    }
  }
}

#waitlist {
  .email_input {
    border: 1px solid $color--border;
  }
}
