#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Utile(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Inutile(';
              }
            }
          }
          .pr-rd-helpful-text {
            width: 100%;
            margin-bottom: 20px;
          }
          .pr-helpful-btn {
            margin: 0 10px 0 0;
            float: left;
          }
        }
      }
    }
  }
}
