@mixin input_checkbox {
  input[type='checkbox'] {
    vertical-align: top;
    margin-top: 3px;
  }
}

@mixin gnav_menu {
  line-height: 30px;
  word-wrap: break-word;
  white-space: pre-line;
  padding: 15px 0;
}
