.enhanced-cart-page {
  .checkout.viewcart {
    #samples-panel {
      .product__select.sample-select-container {
        .sample-select-button.button {
          width: 100%;
        }
      }
    }
    #recommended-products-panel {
      .recommended-item .product_name {
        height: auto;
      }
    }
    #cart-items-panel {
      .cart-items.product-list {
        .cart-item {
          &__subitems {
            margin-bottom: 12px;
            @include breakpoint($bp--medium-up) {
              margin-bottom: 18px;
            }
          }
          &__price {
            .cart-item__product-price--sale {
              @include swap_direction(margin, 0 10px 5px 0);
              color: $color--black;
              @include breakpoint($bp--medium-up) {
                @include swap_direction(margin, 0 17px 0 0);
              }
            }
          }
        }
      }
    }
    .checkout__sidebar {
      .links-panel {
        &__title {
          border-bottom: 0;
        }
      }
      .order-summary-panel {
        .label,
        .value {
          font-family: $ano-bold_regular-font;
          font-size: 15px;
          margin-bottom: 12px;
          @include breakpoint($bp--medium-up) {
            margin-bottom: 9px;
          }
        }
      }
    }
    #viewcart-buttons-panel {
      .payments-icon {
        .master-card {
          background: url('/media/images/checkout/card_mastercard.png') no-repeat;
        }
        .visa-card {
          background: url('/media/images/checkout/card_visa.png') no-repeat;
        }
        .amex-card {
          background: url('/media/images/checkout/card_amex.png') no-repeat;
        }
        .oxxo-cash {
          background: url('/media/images/checkout/cash_oxxo.png') no-repeat;
        }
        .kueski {
          @include swap_direction(margin, 5px 5px 0 0);
          background: url('/media/images/checkout/kueski.svg') no-repeat;
          width: 60px;
        }
        .seveneleven-cash {
          @include swap_direction(margin, 0 16px 0 0);
          background: url('/media/images/checkout/cash_seveneleven.png') no-repeat;
          background-position: center;
          background-size: 100%;
          width: 23px;
        }
        .paypal__checkout--image {
          height: 16px;
          padding-top: 0;
          margin-top: 5px;
        }
        .master-card,
        .visa-card,
        .amex-card,
        .oxxo-cash {
          @include swap_direction(margin, 5px 16px 0 0);
          background-position: center;
          background-size: 100%;
          height: 16px;
          width: 23px;
        }
      }
    }
    #offer-code-panel {
      .offer-code {
        &__byline {
          margin: 0 15px 15px;
          padding: 0;
          color: $color-success;
          a {
            color: $color--black;
          }
        }
        &__input {
          input {
            background-color: $color--white;
          }
        }
        &-panel__content {
          .offer-code__form .offer-code__input.form-item {
            .label {
              line-height: 3.5;
            }
          }
        }
      }
    }
  }
}

.product-brief-v2,
.product-full-v1 {
  .product-price--sale {
    color: $color--red;
  }
}
