body {
  .adpl {
    label {
      display: block;
    }
    .radio {
      label {
        display: inline-block;
      }
    }
  }
}
$adaptive-placeholder: (
  height: 4.8335em,
  margin: 1em,
  font-size: 13px
);
$textarea-placeholder: (
  height: 9.5em,
  textarea: true
);

// Calling adaptive placeholder for input
// code: adplfeature_v1 (used to grep a site for portions of code associated with a given feature)

.adpl {
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'] {
    @include adaptive-placeholder(
      (
        height: map-get($adaptive-placeholder, height),
        margin: map-get($adaptive-placeholder, margin)
      )
    );
    @include swap_direction(border-color, $color--gray--lighter);
    &:focus,
    &.active,
    &.js-label-mode {
      & + label:before {
        webkit-transform: translateY(-2.41675em) translateY(-1px) scale(0.8, 0.8);
        transform: translateY(-2.41675em) translateY(-1px) scale(0.8, 0.8);
      }
    }
    outline: 1px solid #ccc;
  }
  input[name='OFFER_CODE'] {
    &:focus,
    &.active,
    &.js-label-mode {
      & + label:before {
        background: #f0f0f0;
      }
    }
  }
  fieldset {
    overflow: visible;
    max-width: 100%;
  }
  .form-item {
    position: relative;
    overflow: visible;
    margin-bottom: 8px;
  }
  input {
    resize: none;
    outline: none;
    @include swap_direction(border, 1px solid $color--gray--lighter);
    @include swap_direction(border-color, $color--gray--lighter);
    &:required {
      box-shadow: none;
      &.error,
      &:invalid {
        @include swap_direction(border-color, $color--error);
        box-shadow: none;
        &:empty,
        &[value=''],
        &:not(:checked),
        &.empty {
          @include swap_direction(border-color, $color--gray--lighter);
          &.error,
          &.checkedEmpty,
          &.touched {
            @include swap_direction(border-color, $color--error);
          }
        }
      }
      &:valid {
        &.error {
          @include swap_direction(border-color, $color--error);
        }
      }
      &[data-error] {
        @include swap_direction(border-color, $color--error);
      }
    }
  }
  select {
    &::-ms-expand {
      display: none;
    }
  }
  input[type='button'],
  input[type='submit'] {
    &:valid {
      @include swap_direction(border-color, $color--gray--lighter);
    }
  }
}
// Call textarea placeholder
textarea {
  @include adaptive-placeholder($textarea-placeholder);
  @include swap_direction(border-color, $color--gray--lighter);
}

.form-item select {
  & + label {
    span {
      &.label-content {
        display: none;
      }
    }
  }
}
