.site-header {
  .header-search__form--content {
    .header-search__field {
      font-size: 1.7rem;
    }
    .header-search__suggestions {
      .header-search__term-result {
        font-size: 1.7rem;
        top: 20px;
      }
    }
  }
  & &__tools {
    .site-bag__contents {
      &-title {
        text-align: left;
      }
    }
  }
}

.site-container {
  .responsive-container {
    .picker-checkbox {
      .picker-label {
        max-width: 85%;
      }
    }
    .payment_container {
      .payment_form {
        padding: 0 15px;
      }
    }
  }
  .profile-info__fieldset {
    .picker-radio {
      float: left;
      width: auto;
    }
    .gender_col,
    .sms_col,
    .birthday_col {
      label {
        display: block;
      }
    }
    .birthday_col * {
      margin-bottom: 15px;
    }
  }
  .contact-us-page {
    .user-data,
    #email_an_artist {
      width: 100% !important;
      select {
        margin-bottom: 23px;
      }
    }
    .customer-service-title {
      width: auto;
      float: none;
      margin-bottom: 20px;
    }
    .customer-service-description {
      width: auto;
      float: none;
    }
    input[type='text'],
    input[type='email'] {
      width: 100% !important;
      margin: 0 0 23px;
    }
  }
  .email-signup-new-user {
    margin: 0 auto !important;
    #email_new_user_signup {
      .picker-checkbox {
        .picker-handle {
          margin: 0 12px 0 2px;
        }
      }
    }
  }
  .culture-landing {
    &__teaser-wrap {
      height: auto;
    }
  }
  .address-book-page__content {
    padding: 15px;
    .account-page__header {
      .account-page__title {
        border-bottom: 1px solid $color--gray--lighter;
      }
    }
  }
  .past-purchases-page {
    .past-purchases {
      .product--teaser {
        .product__name a {
          border-bottom: none;
        }
      }
    }
  }
  .column-main {
    .multi-use-tout--align-left {
      .multi-use-tout__title {
        @include fontsize-rem($h6-size);
      }
    }
    .content-4col__column--2 {
      margin-top: 20px;
    }
  }
}

.account-order-history {
  .past-purchases {
    .past-purchases {
      &__item {
        .view-details {
          width: auto;
          position: absolute;
          right: 11px;
        }
        .product--teaser {
          .product__add {
            .product__add-to-bag {
              width: 100%;
              font-size: 1rem;
              line-height: 1.4em;
            }
          }
          .product__detail {
            width: 31.66667%;
            .product__name a {
              border-bottom: none;
            }
          }
          .product__qty {
            margin-left: 38.44444%;
          }
          .product__rate {
            margin-left: 56.11111%;
          }
          .product__price {
            margin-left: 67.22222%;
          }
        }
        .order-cost {
          margin-left: 78%;
        }
        .order-status {
          width: 20%;
          margin-left: 40%;
        }
        .product-header {
          .quantity {
            margin-left: 37.44444%;
          }
          .price {
            margin-right: 5%;
          }
        }
      }
    }
  }
}

.sign-in-page {
  a:not(.mantle-cta--custom-textcolor):visited {
    color: $color--white;
  }
}
