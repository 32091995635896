.checkout {
  .registration-panel {
    .create-account {
      .form-submit {
        display: none;
      }
    }
  }
  .payment-panel {
    .payment-edit-content {
      .payment_form {
        .form-submit {
          display: none;
        }
      }
    }
  }
}
