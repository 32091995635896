.site-container {
  .checkout.panel {
    #order-summary-panel {
      .order-summary__shipping-content {
        #checkout_shipmethod {
          label {
            display: inline-block !important;
          }
        }
      }
    }
  }
  .checkout-header {
    border-bottom: 1px solid #dbdbdb;
  }
  .checkout__sidebar {
    #bottom-viewcart-buttons {
      .panel {
        background: #ffffff;
      }
    }
    .panel {
      padding: 1em;
      @include breakpoint($bp--xsmall) {
        .continue-buttons {
          .btn {
            margin-top: 20px;
          }
        }
      }
    }
    #favorites-panel {
      display: none;
    }
  }
  #mobile-breadcrumb {
    display: none;
  }
  .invoice_container {
    position: relative;
    padding: 20px 20px 0 10px;
    .menu-item-blocks {
      display: none;
    }
    .messages.error {
      background-color: #ffffff;
      color: #b84947;
      border-bottom: 0px;
    }
    .form-container {
      .form-item {
        margin: 0.5em;
        display: inline-block;
        width: 100%;
      }
    }
    .continue-button-wrapper {
      .submit {
        input {
          margin: 0.5em;
          width: 100%;
        }
      }
    }
  }
  .checkout__index-content {
    .shipping-edit-content {
      form#checkout_shipping_panel {
        .default-shipping {
          @include input_checkbox;
        }
        .default-billing {
          @include input_checkbox;
        }
        .shipping-edit-address-content__content {
          .sub-section {
            .address-form {
              select {
                height: 60px;
              }
            }
          }
        }
      }
    }
  }
  .viewcart {
    .checkout__sidebar {
      .need-help-panel {
        display: none;
      }
    }
    .checkout__content {
      padding: 0;
      @include breakpoint($bp--xsmall) {
        .continue-buttons {
          .btn {
            margin-top: 20px;
          }
        }
      }
    }
    .top-viewcart-buttons:nth-of-type(1) {
      display: none;
    }
  }
  #confirmation-panel {
    .confirmation-panel__content {
      .confirmation-panel__order-number {
        display: block;
      }
    }
  }
  .how-we-will-use-container {
    .pre {
      .left {
        float: left;
        margin-right: 5px;
      }
      .right {
        float: left;
        width: 95%;
      }
    }
  }
  .checkout__content {
    .checkout-registration {
      .checkout-registration__email-address {
        margin-top: 10px;
      }
    }
  }
}

.viewcart {
  .sign-in-panel {
    .checkout__return-user {
      #checkout_signin {
        .form-submit {
          margin-top: 1.25em;
        }
      }
    }
  }
}

.checkout {
  .left {
    .guarantee-panel {
      display: none;
    }
  }
  .messages-container {
    #error_account_lockout {
      background-color: #f14a29;
      color: #fff;
    }
  }
  .sign-in-panel {
    border-bottom: 1px solid #dbdbdb;
    margin: 0 1em;
  }
  #checkout_shipping_panel {
    select {
      margin-bottom: 0;
    }
  }
  #viewcart-panel {
    .cart-item__price {
      .cart-item {
        &__product-price--non-sale {
          text-decoration: line-through;
        }
        &__product-price--sale {
          color: #f00;
        }
      }
    }
  }
  .checkout-progress-bar {
    margin: 20px 0 0;
    &__list {
      overflow: hidden;
      counter-reset: step;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    &__list-item {
      color: #f0f0f0;
      text-transform: uppercase;
      width: 33.33%;
      float: left;
      position: relative;
      text-align: center;
      font-size: 12px;
      font-family: $ano-bold_regular-font;
      a {
        border-bottom: none;
        color: #f0f0f0;
        text-decoration: none;
        &:hover,
        &:visited {
          color: #f0f0f0;
          text-decoration: none;
        }
      }
      &:before {
        background: $color--white;
        border: 1px solid #f0f0f0;
        color: $color--white;
        content: counter(step);
        counter-increment: step;
        display: block;
        line-height: 13px;
        margin: 0 auto 5px auto;
        position: relative;
        width: 13px;
        z-index: 2;
        @include border-radius(7.5px);
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: #f0f0f0;
        position: absolute;
        left: -50%;
        top: 7px;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
      &--complete {
        color: #000000;
        font-weight: bold;
        a {
          color: #000000;
          &:hover,
          &:visited {
            color: #000000;
          }
        }
        &:before {
          background: #000000;
          color: #000000;
        }
      }
    }
  }
}

.site-container {
  .checkout__content {
    .registration-panel {
      .checkout-registration {
        .checkout-registration__email-address {
          display: block;
          .example-user-email {
            display: inline;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp--medium-up) {
  .site-container {
    .checkout__content {
      .city-state-zip {
        .state {
          width: 100%;
          margin: 0 0 15px 0;
          padding: 0;
        }
        .postal-code {
          width: 100%;
        }
      }
    }
  }
}

.field-container {
  &.city-entered {
    input {
      &.error {
        border: 1px solid $color--red;
        box-shadow: none;
      }
    }
  }
}

.enhanced-cart-page {
  .checkout.viewcart {
    #viewcart-panel {
      .messages-container.error {
        padding: 0;
      }
      .cart-item__qty .qty {
        border-bottom: 1px solid $color--black !important;
        border-top: 1px solid $color--black !important;
        outline: 0;
      }
    }
    .checkout__sidebar {
      .links-panel {
        padding-bottom: 60px;
        margin-bottom: 32px;
      }
    }
    #viewcart-buttons-panel {
      .paypal__checkout--image {
        padding-top: 0;
        margin-top: 5px;
      }
    }
    .sticky-checkout {
      &-button {
        .continue-checkout__lock--icon {
          vertical-align: middle;
        }
      }
      &-overlay {
        .continue-buttons__group .continue-checkout {
          .continue-checkout__lock--icon {
            @include swap_direction(margin, 0 10px 5px 0);
          }
        }
      }
    }
  }
}
