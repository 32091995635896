// Persistent Sticky Checkout Styles
@mixin sticky_checkout_styles {
  .sticky-checkout-button {
    position: fixed;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
    z-index: 1002;
    background: $color--white;
    border-top: 1px solid $color--black;
    border: none;
    padding: 30px;
    .btn {
      width: 100%;
      font-size: 18px;
      margin: 10px 0;
      img {
        margin-#{$ldirection}: 0;
        padding: 0;
        vertical-align: top;
        &.afterpay {
          display: none;
          width: 90px;
          vertical-align: middle;
        }
      }
      .or-text-afterpay {
        display: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .apple-pay-button-text {
      border: 0;
    }
    .continue-buttons {
      span.pc-hidden {
        display: inline;
      }
      img {
        vertical-align: top;
        padding-top: 16px;
        margin-#{$ldirection}: 5px;
      }
    }
    .viewcart-buttons-panel {
      padding: 0;
      .continue-buttons__group {
        display: block;
      }
    }
  }
  .address-delete__info {
    text-transform: capitalize;
  }
  .viewcart {
    .viewcart-buttons-panel {
      border-bottom: none;
      .continue-buttons__group {
        display: inline-table;
        .btn {
          border: 1px solid $color--black;
        }
      }
      .btn {
        border: none;
        max-width: 228px;
        padding: 0;
        font-size: 18px;
        margin: 10px 0;
      }
      .or-text {
        text-align: center;
        text-transform: uppercase;
        font-family: $ano-bold_regular-font;
        font-size: 18px;
        display: block;
      }
      .continue-samples {
        padding-bottom: 7px;
        display: block;
      }
    }
    .site-container {
      .checkout__sidebar {
        padding-bottom: 125px;
      }
    }
  }
  .overlay-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    background-color: $color--black;
    opacity: 0.5;
    z-index: 1001;
  }
  .checkout {
    .btn.mobile-hidden {
      display: none;
    }
    .viewcart-buttons-panel {
      display: none;
      @include breakpoint($bp--medium-up) {
        display: block;
      }
    }
    .sticky-checkout-overlay {
      .viewcart-buttons-panel {
        display: block;
        padding: 0;
      }
    }
    &.viewcart {
      .checkout__sidebar {
        padding-bottom: 125px;
      }
    }
    .shipping-panel {
      .shipping-edit-content {
        input.btn-primary {
          display: none;
        }
      }
    }
  }
}
